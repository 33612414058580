<script lang="ts" setup>
const props = defineProps<{
  isLoading: boolean
  size?: number
  label?: string
}>()

const dotSize = computed(() => props.size ? `${props.size * 6}px` : '6px')
const transformDistance = computed(() => props.size ? `${props.size * -6}px` : '-6px')
</script>

<template>
  <div v-if="props.isLoading" class="loading-container" flex="~ col" items-center justify-center gap-6>
    <template v-if="label">
      <p text="neutral-600 lg" mb-6>
        {{ label }}
      </p>
    </template>
    <div class="loading-dots" flex items-center justify-center>
      <span
        v-for="index in 5"
        :key="index"
        bg-primary-400
        block
        :style="{
          'width': dotSize,
          'height': dotSize,
          '--transform-distance': transformDistance,
        }"
        mx-2px
        rounded-full
      />
    </div>
    <template v-if="label">
      <p text="neutral-400 md" mt-2>
        {{ $t('components.loading.label') }}
      </p>
    </template>
  </div>
</template>

<style scoped>
.loading-container {
  animation: pulse 1.6s infinite ease-in-out;
}

.loading-dots span {
  animation: loading-dots 0.8s infinite alternate;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loading-dots {
  to {
    opacity: 0.3;
    transform: translateY(var(--transform-distance));
  }
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.7; }
  100% { opacity: 1; }
}
</style>
